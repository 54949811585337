import React from 'react';

import { AnchorHTMLAttributes } from 'react';
import { css } from '@emotion/core';

import { focus, Vars } from 'style';

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  kind?: 'positive' | 'neutral';
};

export default function Link(props: Props) {
  const { kind = 'positive', children, ...rest } = props;

  return (
    <a {...rest} css={[style(kind), focus]}>
      {children}
    </a>
  );
}

const style = (kind: 'positive' | 'neutral') => css`
  font-weight: bold;
  color: ${kind === 'neutral' ? Vars.TextColor : Vars.PositiveColor};
  transition: 0.25s color ease-in-out;
  text-decoration: ${kind === 'positive' ? 'none' : 'underline'};

  &:hover {
    color: ${kind === 'neutral' ? Vars.TextColor : Vars.PositiveHoverColor};
  }
`;
