import React from 'react';

// libraries
import { ReactNode } from 'react';
import { css, Global } from '@emotion/core';

import global from 'style';

import Head from 'components/Head';
import Navigation from 'components2/Navigation';
import Footer from 'components2/Footer';
import favicon from 'images/favicon.ico';

interface Props {
  children: ReactNode;
  hero?: boolean;
}

export default function MainLayout(props: Props) {
  const { children, hero } = props;

  return (
    <>
      <Global styles={global} />
      <Head>
        <title>Parsec</title>
        <link rel="shortcut icon" href={favicon} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="fb:admins" content="248520192163731" />
      </Head>
      <Navigation css={navigation(!!hero)} color="light" legacy />
      {children}
      <Footer legacy />
      {/* Google Tag Manager (noscript) */}
      <noscript>
        {/* eslint-disable-next-line */}
        <iframe
          title=""
          src="https://www.googletagmanager.com/ns.html?id=GTM-PTKRK7"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
    </>
  );
}

const absoluteStyle = css`
  position: absolute;
  top: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
`;

const normalStyle = css`
  margin: 0 auto;
`;

const navigation = (absolute: boolean) => css`
  width: 100%;
  ${absolute ? absoluteStyle : normalStyle};
`;
