import React from 'react'

// libraries
import { css } from '@emotion/core';

import { Vars } from 'style';

interface Item {
  text: string;
  href: string;
  children?: Item[];
}

interface Props {
  className?: string;
  items: Item[];
}

export default function NavigationList(props: Props) {
  const { className, items } = props;

  return (
    <nav className={className} css={wrapper}>
      <ul css={navigationList}>
        {items.map(item => (
          <NavigationItem key={item.text} item={item} level={0} />
        ))}
      </ul>
    </nav>
  );
}

function NavigationItem(props: { item: Item; level: number }) {
  const { item, level } = props;

  return (
    <li>
      <a href={item.href} css={navigationLink(level)}>
        {item.text}
      </a>
      {item.children && (
        <ul css={navigationList}>
          {item.children.map(child => (
            <NavigationItem key={child.text} item={child} level={level + 1} />
          ))}
        </ul>
      )}
    </li>
  );
}

const wrapper = css`
  overflow: auto;
  background-color: ${Vars.Shade300};
  border-radius: ${Vars.BorderRadius};
  padding: 0.8rem 0;
`;

const navigationList = css`
  list-style: none;
  padding: 0;
`;

const navigationLink = (level: number) => css`
  display: block;
  line-height: 2.4rem;
  padding: 1.2rem 2rem 1.2rem ${2 + level * 3.2}rem;
  font-weight: bold;
  transition: background-color 0.25s ease-in-out;

  &:hover,
  &:focus {
    background-color: ${Vars.Tint200};
    outline: 0;
  }
`;
